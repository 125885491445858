import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GovernanceVisualizerViewComponent } from './components/gov-viz-view.component';
import { GovernanceVisualizerHomeComponent } from './components/gov-viz-home.component';

const routes: Routes = [
  { path: '', component: GovernanceVisualizerHomeComponent, pathMatch: 'full' }, 
  { path: 'view/:tenant', component: GovernanceVisualizerViewComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GovernanceVisualizerRoutingModule { }
