import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { GmsStore } from '../../../services/gms.store';
import { GmsConfigService } from '../../../services/gms-configuration.service';

export const ReportApiUrl = {
  GovernanceVisualizerGetFile: (tenant: string) => `/governance-visualizer/${tenant}`,
}

@Injectable({providedIn: 'root'})
export class GovernanceVisualizerService extends BaseApiService{

  constructor(config: GmsConfigService, store: GmsStore) {
    super(config, store);
  }

  getFile(tenant: string): Observable<Blob> {
    return super.getBlob(ReportApiUrl.GovernanceVisualizerGetFile(tenant));
  }

}
