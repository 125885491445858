
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { GovernanceVisualizerService } from '../api/governance-visualizer.service';

@Component({
  templateUrl: './gov-viz-view.component.html',
  styles: `
    :host {
      width: 100%;
      height: 100%;
    }
  `
})
export class GovernanceVisualizerViewComponent implements OnInit {
  @ViewChild('contentContainer', { static: true }) contentContainer!: ElementRef;

  tenant: string | null = null;
  content: string | null = null;
  reportUrl: SafeResourceUrl | null = null;
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private service: GovernanceVisualizerService,
  ) {
    this.route.paramMap.subscribe(params => {
      this.tenant = params.get('tenant') || null;
      this.refresh();
    });
  }

  async ngOnInit() {
  }

  async refresh() {
    if (this.tenant == null) {
      this.reportUrl = null;
    } else {
      var response = await lastValueFrom(this.service.getFile(this.tenant));
      const blobUrl = URL.createObjectURL(response);
      this.reportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    }
  }
}
