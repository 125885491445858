import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlockUIModule } from 'primeng/blockui';

import { GovernanceVisualizerViewComponent } from './components/gov-viz-view.component';
import { GovernanceVisualizerRoutingModule } from './governance-visualizer-routing.module';
import { GovernanceVisualizerHomeComponent } from './components/gov-viz-home.component';


@NgModule({
  declarations: [
    GovernanceVisualizerViewComponent,
    GovernanceVisualizerHomeComponent
  ],
  imports: [
    CommonModule,
    BlockUIModule,

    GovernanceVisualizerRoutingModule
  ]
})
export class GovernanceVisualizerModule {
}
