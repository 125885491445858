@if(reportUrl){
<iframe [src]="reportUrl"
        frameborder="0"
        crossorigin="anonymous"
        loading="lazy"
        referrerpolicy="no-referrer"
        sandbox="allow-scripts allow-popups allow-same-origin allow-downloads allow-forms"
        class="w-full h-full"></iframe>
}
@else{
<div class="text-center p-5">
  <i>Loading...</i>
</div>
}
